<template>
<Transition name="slide-fade" appear>
<div v-if='assessment' class="bg-white text-t3 container"> 
    <div class="rounded shadow-t3 items-center relative md:mt-0"> 

            <!-- Assessment Customer Profile  -->
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-5 md:gap-44 -2 mt-6">
            <div aria-label="card 1"
                class="focus:outline-none bg-white p-6 space-y-1 dark:text-white">
                <div class="flex items-center justify-between pb-5 m-0">
                    <img :src="assessment.customer_image" alt="user" class=" w-20 h-20 rounded-xl" />
                    <div class="flex items-start justify-between w-full overflow-hidden">
                        <div class="pl-3 w-5/6">
                            <p class="focus:outline-none text-xl font-medium leading-5">{{assessment.customer_name }}</p>
                            <p class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-t3-nightText">{{assessment.customer_id}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex ml-auto items-center">
                <div class="flex flex-col align-center  p-6 w-full">
                    
                    <div class=" mr-8 flex items-center my-3 p-3" >
                       
                        <!-- <BaseTimer :timeUP="settings.assessment_duration"/> -->
                       
                    </div>
                </div>
            </div>
        </div>
            <!-- Question Display Tabs -->
        <div class="shadow-t3 bg-white p-2 px-12 xl:px-36 rounded">
            
        <ul  class="nav nav-tabs flex flex-col md:flex-row flex-wrap justify-center list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="tablist">
            <li v-for="(task,index) in sortedTask() " :key="task.id" class="nav-item" role="presentation">
                <button :href="'#a'+task.id" :id="task.id" :value="task.id " class="nav-link block text-base xl:text-base  font-medium leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-3 py-2 xl:px-6 xl:py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" data-bs-toggle="pill" :data-bs-target="'#a'+task.id" role="tab" :aria-controls="'tabs-'+task.id"
                aria-selected="true" :class="{ active : index === 0 }">{{task.name}}</button>
            </li>
            </ul>

            <!-- Tab Content -->

            <div v-if="!summary" class="tab-content" id="tabs-tabContent">
            <div v-for="(task,index) in sortedTask() " :key="index" class="tab-pane fade" :class="{ 'active show': index === 0 }" :id="'a'+task.id" role="tabpanel" aria-labelledby="tabs-text-tab">
                <div>     
                <ul>
                    <li class="text-xl font-semibold text-center text-t3 my-64" v-if="questionTask(task.id).length === 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>It appears you have encountered an error!! It appears there are no questions here yet.</p>
                     </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'txt').length !== 0">
                    </li>
                    <li class=" border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'txt')" :key="question.id">
                        <span v-if="question.type === 'txt'">{{question.text}}</span>
                    </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'img').length !== 0">
                    </li>
                    <li class="grid md:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4">
                        <div class=" border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'img')" :key="question.id">
                                <img class="w-full object-cover cursor-zoom-in rounded hover:drop-shadow-xl hover:scale-150"  v-if="question.type == 'img'" :src="question.content" :alt="question.description"/>
                            
                        </div>
                    </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'audio').length !== 0">
                    </li>
                    <li >
                        <div class="grid grid-cols-2 w-full  content-center text-xl text-t3 border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'audio')" :key="question.id">
                            <audio :src="question.content" controls><source type="audio/mp3"></audio>
                            <p class="ml-auto mr-8">{{question.description}}</p>
                        </div>
                    </li>
                  
                    
                </ul>
                </div>
            </div>

        </div> 
        </div>
        
    </div>


</div>
</Transition>

</template>
<style>
		.audio__player-play{
		display:none !important;
	}
</style>
<script>
import { watchEffect } from 'vue';
import { mapActions, mapState } from 'vuex';
// import AssessmentService from "../services/AssessmentsServices"
// import BaseTimer from "@/components/BaseTimer";
import MicRecorder from 'mic-recorder-to-mp3';
// import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    export default {
		props: {
			id: {
				type: String,
				required: true
			}
		},
          components: {
    // BaseTimer,
  },
        data() {
			return {
                summary:false,
                counterA:0,
                displayImage:false,
                srcDisplay:'',
                buttonP:false,
                recordingNow:false,
                recordAvailable:false,
                recorder:null,
				isClicked: false,
				isCompleted: true,
				currTab:null,
                ticked:true,
                uploading:false,
                recording:null,
                recordings:[],
                grading:{
                    pronunciation:null,
                    structure:null,
                    vocabulary:null,
                    fluency:null,
                    comprehension:null,
                    interaction:null,
                },
                AudioAttrs: {
					src: "",
					title: "Select an audio to play",
					coverRotate: false,
					progressBarColor: "#FCBD0B",
					indicatorColor: "0E2D5B",

				},
			}
		},

        methods: {
      callback (data) {
        console.log(data)
      },
      
        ...mapActions({
            getassessment: 'Assessments/getAssessment',
            getTasks: 'Assessments/getAllTasks',
            getquestions: 'Questions/getAllQuestions',
            getsample: 'Samples/getSample',
            getsettings: 'Settings/getAllSettings',
        }),
			changeURL(event) {
				this.AudioAttrs.src = event.currentTarget.value
				this.AudioAttrs.title = event.currentTarget.name
			},
        playButtn(){
            document.getElementsByClassName('audio__player-play')[0].click()
			},
       
        assessmentCustomer(sentID){
			return this.customers.find(cus => cus.id === sentID)},	

        questionTask(name){
            return this.sampledQuestions.filter(q => q.for_task === name )
        },

        questionFilter(questionList, type){
            return questionList.filter(q => q.type === type)
        },
        sort_by_id() {
        return function (elem1, elem2) {
          if (elem1.id < elem2.id) {
            return -1;
          } else if (elem1.id > elem2.id) {
            return 1;
          } else {
            return 0;
          }
        };
      },
        sortedTask(){
            return this.tasks.sort(this.sort_by_id())
        },
        submitReport(taskid,name){
            this.counterA++
            this.stopRecording(taskid,name)
            if(this.sortedTask().find(t =>  t.id === taskid+1)){

                document.getElementById(taskid+1).click()
                this.currTab=taskid+1;
                this.startRecording(name)
            }
            else{
                this.recordingNow=false
               this.summary=true
            }
            
                    
        }

		},

        computed: {
        ...mapState({
			assessment: state => state.Assessments.assessment,
            tasks: state => state.Assessments.tasks,
            questions: state => state.Questions.questions,
            sample: state => state.Samples.samples,
            settings: state => state.Settings.settings
        }),
        sampledQuestions(){
            return this.sample.questions
        }
		},

        created() {
            this.recorder = new MicRecorder({
        bitRate: 192
        });
			this.emptyState = 'Sorry!, we couldnt find matching results'
            
			this.getTasks()
            this.getassessment(this.id)
			
			this.getquestions()
            this.getsettings()
            watchEffect(()=>{
                this.getsample(this.assessment.sample)
                this.currTab = this.sortedTask()[0].id
            } )

		},
    }
</script>

<style>

</style>